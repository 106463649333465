@import "/node_modules/swiper/css/swiper.min.css";

html,
#app {
  max-width: 600px;
  margin: 0 auto;
  min-width: 320px;
  background-color: #f9f9f9;
}

#app > div {
  background: white;
}
body #root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: Lato, sans-serif;
}

body input::-webkit-inner-spin-button,
body input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.highcharts-crosshair-category {
  display: none !important;
  visibility: hidden !important;
}

body input[type="number"] {
  -moz-appearance: textfield;
}

body input {
  touch-action: none;
}

.spends-swiper-container{
  width: 130px;
  overflow: hidden;
}